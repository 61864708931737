import { render, staticRenderFns } from "./OkrList.vue?vue&type=template&id=7cdee633&scoped=true&"
import script from "./OkrList.vue?vue&type=script&lang=js&"
export * from "./OkrList.vue?vue&type=script&lang=js&"
import style0 from "./OkrList.vue?vue&type=style&index=0&id=7cdee633&lang=scss&scoped=true&"
import style1 from "./OkrList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cdee633",
  null
  
)

export default component.exports